<template>
  <div>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="(tab, index) in tabs"
        :key="index"
        :to="{ name: tab.route }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="[
          tab.rpute ? 'router-link-active' : '',
          'sub-tab-nav',
          'px-3',
          bgTabsNavs,
        ]"
      >
        {{ tab.name }}
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-top-info border-3 border-table-radius px-0">
      <router-view ref="routerView" :key="$route.name" />
    </b-card>
  </div>
</template>
<script>
export default {
  computed: {
    tabs() {
      return this.$route.matched[4].meta.tabs;
    },
  },
};
</script>